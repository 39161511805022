<template>
  <div class="main-container">
    <div class="section-topic">
      <div class="title-header">
        <h1>{{ $t("exchange.title") }}</h1>
      </div>
    </div>
    <div class="section-action">
      <div class="search-action">
        <v-text-field
            class="btn btn-color btn-border btn-border-color"
            outlined
            dense
            prepend-inner-icon="mdi-magnify"
            :placeholder="$t('exchange.search')"
            v-model="searchItem"
            @keypress.enter="searchFilterItem"
        >
        </v-text-field>
      </div>
      <div class="btn-new-create-action">
        <v-btn class="btn-create" @click="onCreate()">{{ $t("exchange.create") }}</v-btn>
      </div>
    </div>
    <div class="section-form-content">
      <div class="block-content">
        <table class="table table-view" v-if="listExchange.length > 0">
          <thead>
          <tr>
            <th class="text-left">{{ $t("exchange.no") }}</th>
            <th class="text-left">{{ $t("exchange.name") }}</th>
            <th class="text-left">{{ $t("exchange.short Name") }}</th>
            <th class="text-left">{{ $t("exchange.rate") }}</th>
            <th class="text-left"></th>
          </tr>
          </thead>
          <tbody>
          <tr v-for="(item, idx) in listExchange" :key="idx">
            <td>{{ idx + 1 }}</td>
            <td>
              {{ item.name }}
            </td>
            <td>
              {{ item.short }}
            </td>
            <td>
              {{ $helpers.numberFormatter(item.rate) }}
            </td>
            <td>

            </td>
            <td class="text-end">
              <v-menu transition="slide-y-transition" bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn outlined v-bind="attrs" v-on="on">
                    <i class="fas fa-ellipsis-v btn-ellipsis"></i>
                  </v-btn>
                </template>
                <v-list>
                  <v-list-item>
                    <v-list-item-title
                        class="btn-edit"
                        @click="onEdit(item.id)"
                    >Edit
                    </v-list-item-title
                    >
                  </v-list-item>
                  <v-list-item>
                    <v-list-item-title
                        class="btn-delete"
                        @click="onDeleted(item.id)"
                    >Delete
                    </v-list-item-title
                    >
                  </v-list-item>
                </v-list>
              </v-menu>
            </td>
          </tr>
          </tbody>
        </table>
        <defaultTableNoResult v-else/>
        <Loading v-if="isLoading"/>
        <ModalEdit fullscreen title="Edit">
          <template v-slot="{ close }">
            <EditExchange
                :exchange="exchange"
                @close="close"
                @success="fetchExchange"
            />
          </template>
        </ModalEdit>

        <ModalDelete>
          <template v-slot="{ close }">
            <DeleteExchange
                :exchangeId="exchangeId"
                @close="close"
                @success="fetchExchange"
            />
          </template>
        </ModalDelete>
      </div>
    </div>
  </div>
</template>

<script>
import Loading from "../../../components/Loading";
import DeleteExchange from "@/views/MainCompany/Exchange/form/DeleteExchange";
import EditExchange from "@/views/MainCompany/Exchange/form/EditExchange";
import defaultTableNoResult from "../../../components/defaultTableNoResult";

export default {
  components: {
    Loading,
    defaultTableNoResult,
    EditExchange,
    DeleteExchange
  },
  data() {
    return {
      modalEditEducation: false,
      isLoading: true,
      listExchange: [],
      exchange: {},
      exchangeId: "",
      searchItem: "",

    };
  },
  methods: {
    searchFilterItem() {
      this.fetchExchange();
    },
    filterExchange(exchange_id) {
      return (
          this.listExchange.filter((item) => {
            return item.id == exchange_id;
          })[0] || {}
      );
    },

    onCreate() {
      this.$router.push({
        name: "exchange.create",
      }).catch(() => {
      });
    },

    onEdit(exchange_id) {
      this.exchange = {
        ...this.filterExchange(exchange_id),
      };
      this.$store.commit("modalEdit_State", true);
    },

    onDeleted(exchange_id) {
      this.exchangeId = exchange_id;
      this.$store.commit("modalDelete_State", true);
    },

    fetchExchange() {
      this.isLoading = true;
      this.$axios
          .get(`company/list/exchanges`, {
            params: {
              filter: this.searchItem,
            },
          })
          .then((res) => {
            if (res.status === 200) {
              this.isLoading = false;
              this.listExchange = res.data.data;
              console.log(this.listExchange)
            }
            this.isLoading = false;
          })
          .catch((error) => {
            console.log(error);
            this.isLoading = false;
          });
    },
  },
  created() {
    this.fetchExchange();
  },
};
</script>

<style scoped lang="scss">
</style>
